import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/default",
    component: _763b5a69,
    name: "home___default"
  }, {
    path: "/german",
    component: _763b5a69,
    name: "home___german"
  }, {
    path: "/default/cart",
    component: _1d513f9d,
    name: "cart___default"
  }, {
    path: "/default/checkout",
    component: _a301d77a,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___default"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___default"
    }]
  }, {
    path: "/default/Cms",
    component: _740316af,
    name: "Cms___default"
  }, {
    path: "/default/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "/default/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/default/Faq",
    component: _03c11c08,
    name: "Faq___default"
  }, {
    path: "/default/Home",
    component: _763b5a69,
    name: "Home___default"
  }, {
    path: "/default/Page",
    component: _731f1fce,
    name: "Page___default"
  }, {
    path: "/german/cart",
    component: _1d513f9d,
    name: "cart___german"
  }, {
    path: "/german/checkout",
    component: _a301d77a,
    name: "checkout___german",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___german"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___german"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___german"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___german"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___german"
    }]
  }, {
    path: "/german/Cms",
    component: _740316af,
    name: "Cms___german"
  }, {
    path: "/german/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___german",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___german"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___german"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___german"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___german"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___german"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___german"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___german"
    }, {
      path: "/german/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___german"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___german"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___german"
    }]
  }, {
    path: "/german/Faq",
    component: _03c11c08,
    name: "Faq___german"
  }, {
    path: "/german/Home",
    component: _763b5a69,
    name: "Home___german"
  }, {
    path: "/german/Page",
    component: _731f1fce,
    name: "Page___german"
  }, {
    path: "/default/:slug+",
    component: _731f1fce,
    name: "page___default"
  }, {
    path: "/german/:slug+",
    component: _731f1fce,
    name: "page___german"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
